import { Injectable } from "@angular/core";
import { AngularFirestore, Action, DocumentSnapshot } from '@angular/fire/firestore';
import { PantallaModel } from '../../modelos/pantalla.model';
import { AuthService } from '../../auth.service';
import { auth } from 'firebase';
import { Observable, Subscription } from 'rxjs';
import { CommandModel } from '../../modelos/command.model';

@Injectable()
export class OrdenesService {

    sendCommand(command: any, id: string) {
        let t = new Date();
        let timestamp = t.getTime();
        command.timestamp = timestamp;
        return this.db
            .collection("ordenes")
            .doc(id)
            .set(command, { merge: true });
    }

    reportStatus(thumb: string, id: string) {
        let t = new Date();
        let timestamp = t.getTime();

        return this.db
            .collection("ordenes")
            .doc(id)
            .set({ timestamp: timestamp, response: { type: "status", response: thumb, sender: "screen" }, command: null, company: this.authService.company }, { merge: true });
    }

    constructor(
        public db: AngularFirestore,
        private authService: AuthService
    ) {

    }

    tiempoReal(): Observable<Action<DocumentSnapshot<any>>> {
        var ordenes = this.db.collection("ordenes").doc(this.authService.userId).snapshotChanges();
        return ordenes;
    }

    tiempoRealById(id: string): Observable<Action<DocumentSnapshot<any>>> {
        var ordenes = this.db.collection("ordenes").doc(id).snapshotChanges();
        return ordenes;
    }

    livenessPantalla(id: string): Promise<void> {
        let d = new Date();
        return this.db
            .collection("ordenes")
            .doc(id)
            .set({ online: d, company: this.authService.company }, { merge: true });
    }
}
