import * as firebase from 'firebase';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage, AngularFireUploadTask, AngularFireStorageReference } from '@angular/fire/storage';
import { MediaModel } from '../../modelos/media.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { Observable, Subscription } from 'rxjs';
import { stringify } from 'querystring';

@Injectable({
    providedIn: 'root'
})
export class MediaService {

    constructor(
        private storage: AngularFireStorage,
        public db: AngularFirestore,
        private auth: AuthService,
        private http: HttpClient
    ) { }

    public newGuid() {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    //Tarea para subir archivo
    public tareaCloudStorage(nombreArchivo: string, datos: any): AngularFireUploadTask {
        return this.storage.upload(nombreArchivo, datos);
    }

    //Referencia del archivo
    public referenciaCloudStorage(nombreArchivo: string): AngularFireStorageReference {
        return this.storage.ref(nombreArchivo);
    }

    public referenciaCloudStorageRef(nombreArchivo: string): AngularFireStorageReference {
        return this.storage.ref("media/" + this.auth.company + "/" + nombreArchivo);
    }

    public borrarArchivo(ref): Observable<any> {
        let company = this.auth.company;
        return this.storage.ref("media/" + company + '/' + ref).delete();
    }

    medias(company: string): Promise<any> {
        return new Promise(resolve => {
            var medias = this.db.collection("media", medias => medias.where("company", "==", company)).get().subscribe(pl => {
                resolve(pl);
            });
        });
    }

    encontrarMediaEnPlaylist(id: string): Promise<any> {
        return new Promise(resolve => {
            this.db.collection("playlists", medias => medias.where("company", "==", this.auth.company)).get().subscribe(media => {
                resolve(media);
            });
        });
        
    }

    borrarMedia(id: string, data: MediaModel): Promise<any> {
        this.decreaseMedia(data);

        return this.db
            .collection("media")
            .doc(id)
            .delete();
    }

    actualizarMedia(id: string, data: MediaModel): Promise<void> {
        data.company = this.auth.company;
        return this.db
            .collection("media")
            .doc(id)
            .set(data, { merge: true });
    }

    media(id: string): Promise<any> {
        return this.db
            .collection("media")
            .doc(id).ref.get();
    }

    public getCounters(): Promise<any> {
        return this.db.collection("counters").doc(this.auth.company).ref.get();
    }


    private increment = firebase.firestore.FieldValue.increment(1);
    private incVideos() {
        this.db.collection("counters").doc(this.auth.company).update({ videos: this.increment });
    }

    private incImage() {
        this.db.collection("counters").doc(this.auth.company).update({ images: this.increment });
    }

    private incHtml() {
        this.db.collection("counters").doc(this.auth.company).update({ htmls: this.increment });
    }

    private decrement = firebase.firestore.FieldValue.increment(-1);
    private decVideos() {
        this.db.collection("counters").doc(this.auth.company).update({ videos: this.decrement });
    }

    private decImage() {
        this.db.collection("counters").doc(this.auth.company).update({ images: this.decrement });
    }

    private decHtml() {
        this.db.collection("counters").doc(this.auth.company).update({ htmls: this.decrement });
    }

    private increaseMedia(data: MediaModel) {
        if (data.tipo == 0) {
            this.incImage();
        } else if (data.tipo == 1) {
            this.incVideos();
        } else if (data.tipo == 2) {
            this.incHtml();
        }
    }

    private decreaseMedia(data: MediaModel) {
        if (data.tipo == 0) {
            this.decImage();
        } else if (data.tipo == 1) {
            this.decVideos();
        } else if (data.tipo == 2) {
            this.decHtml();
        }
    }

    crearMedia(data: MediaModel): Promise<any> {
        this.db.collection("counters").doc(this.auth.company).set({ counters: this.auth.company }, { merge: true });
        this.increaseMedia(data);
        data.company = this.auth.company;
        return this.db
            .collection("media")
            .add(data);
    }

    reauthenticateWithTokenAndUpload(file) {
        const destinationPath = `$media/${this.auth.company}/${file.name}`;
        this.reauthenticateWithTokenFor(destinationPath)
            .then(() => {
                return this.upload(file, destinationPath).then(() => {
                    //Done
                })
            })
            .catch(function (error) {

            });
    }

    reauthenticateWithTokenFor(path): Promise<firebase.auth.UserCredential | Subscription> {
        return this.auth.afAuth.auth
            .currentUser
            .getIdTokenResult()
            .then((idToken) => {
                const url = 'https://us-central1-screenads-16b77.cloudfunctions.net/uploadToken?token=' + stringify(idToken) + 'path=' + stringify(path);
                return firebase.functions().httpsCallable("uploadToken")({ token: idToken.token, path: path }).then((response: any) => { //this.http.get(url).subscribe((response: any) => {
                    return this.auth.afAuth.auth.signInWithCustomToken(response.data.data);
                });
            });
    }

    upload(file, destinationPath): AngularFireUploadTask {
        return this.storage
            .ref(destinationPath)
            .put(file);
    }
}