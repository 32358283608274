import { Component, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import { PantallaService } from '../core/app/servicios/pantalla.service';
import { PantallaModel } from '../core/modelos/pantalla.model';

@Component({
  selector: 'page-pantallas',
  templateUrl: 'pantallas.component.html',
  styleUrls: ['pantallas.scss']
})
export class PantallasComponent implements OnInit{
  pantallas: PantallaModel[];
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public location : Location,
    public fb: FormBuilder,
    private router: Router,
    public pantallaService: PantallaService
  ) {

  }

  ngOnInit(): void {
    this.pantallaService.pantallas(this.authService.company).then(res => {
      this.pantallas = res.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        return model;
      });      
    }, err => console.log(err))
  }



  nuevaPantalla(){
    this.router.navigate(['/pantallas/0']);
  }
}
