import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { ValueModel } from 'src/app/core/modelos/value.model';
import { PantallaService } from 'src/app/core/app/servicios/pantalla.service';
import { PantallaModel } from 'src/app/core/modelos/pantalla.model';
import { AuthService } from 'src/app/core/auth.service';
import * as firebase from 'firebase';
import { MatDialog, MatSnackBar, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '@angular/material';
import { CommonDialog } from 'src/app/core/commondialog/common.dialog.component';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Observable, Subject } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { GruposService } from 'src/app/core/app/servicios/grupos-service';
import { OrdenesService } from 'src/app/core/app/servicios/ordenes.service';
import { CalendarEvent, WeekDay } from 'calendar-utils';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';

@Component({
  selector: 'editor-pantalla',
  templateUrl: 'editor-pantalla.component.html',
  styleUrls: ['editor-pantalla.scss']
})
export class EditorPantallaComponent implements OnInit {
  busy: boolean = false;
  model: PantallaModel;
  nuevaPantallaForm: FormGroup;
  ready: boolean = false;
  pantalla: any;
  filteredGroups: Observable<string[]>;
  tituloPantalla: string;
  allGroups: string[] = [];
  groups: string[] = [];
  tiposPantalla: ValueModel[] = [{ valor: 0, nombre: "Restaurante" }, { valor: 1, nombre: "Museo" }, { valor: 2, nombre: "Taxi" }, {valor: 3, nombre: "Mercado"}];
  @ViewChild('grupoInput', { static: false }) grupoInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    public pantallaService: PantallaService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private playlistService: PlaylistService,
    private groupService: GruposService,
    private ordenesService: OrdenesService
  ) {

  }

  public ENTER = ENTER;
  public COMMA = COMMA;

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let res = this.allGroups.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);;
    res = res.filter(t => !this.groups.some(x => x.toLocaleLowerCase() == t.toLocaleLowerCase()))
    return res;
  }

  removeUsed(): string[] {
    let res = this.allGroups.map(t => t);
    res = res.filter(t => !this.groups.some(x => x.toLocaleLowerCase() == t.toLocaleLowerCase()))
    return res;
  }

  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cerrar", { politeness: "assertive", horizontalPosition: "center", verticalPosition: "top", duration: 3000 })
  }

  desocupado() {
    this.busy = false;
  }

  conErrores() {
    this.openSnackBar("Se produjo un error al intentar realizar la operación")
  }

  sinErrores() {
    this.openSnackBar("Operación completada")
  }

  get nombre() { return this.nuevaPantallaForm.get('nombre'); }
  get tipo() { return this.nuevaPantallaForm.get('tipo'); }
  get descripcion() { return this.nuevaPantallaForm.get('descripcion'); }
  get passwordVal() { return this.nuevaPantallaForm.get('passwordVal'); }
  get gruposCtrl() { return this.nuevaPantallaForm.get('gruposCtrl'); }

  createForm() {
    this.nuevaPantallaForm = this.fb.group({
      nombre: ['', Validators.required],
      descripcion: ['', Validators.maxLength(80)],
      tipo: ['', Validators.required],
      passwordVal: ['', [Validators.required, Validators.minLength(6)]],
      gruposCtrl: [[], Validators.nullValidator]
    });
    this.ready = true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.groups.push(event.option.viewValue);
    this.grupoInput.nativeElement.value = '';
    this.gruposCtrl.setValue(null);
  }

  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our fruit
      if ((value || '').trim()) {
        this.groups.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = '';
      }

      this.gruposCtrl.setValue(null);
    }
  }

  removeGroup(group: string): void {
    const index = this.groups.indexOf(group);

    if (index >= 0) {
      this.groups.splice(index, 1);
    }
  }

  updateForm(pantalla) {
    this.nuevaPantallaForm = this.fb.group({
      nombre: [pantalla.nombre, Validators.required],
      descripcion: [pantalla.descripcion, Validators.maxLength(80)],
      tipo: [pantalla.ubicacion, Validators.required],
      passwordVal: [pantalla.password, [Validators.required, Validators.minLength(6)]],
      gruposCtrl: [pantalla.grupos, Validators.nullValidator]
    });
    this.groups = pantalla.grupos;
    this.ready = true;
    this.tituloPantalla = "Editando la pantalla: " + pantalla.nombre.toLowerCase() + "@screenads.app";
  }

  tempList: any;
  salvar() {
    debugger;
    let datos = <PantallaModel>{ nombre: this.nombre.value, descripcion: this.descripcion.value, ubicacion: this.tipo.value, password: this.passwordVal.value, grupos: this.groups, programada: this.model.programada, programa: this.model.programa };
    this.groupService.actualizarGrupos(this.allGroups.concat(this.groups));
    datos.playlist = this.model.playlist;
    this.busy = true;
    if (this.pantalla != "0") {
      if (this.tempList) {
        datos.playlist = [this.tempList];
      }
      this.pantallaService.actualizarPantalla(this.pantalla, datos).then(() => this.sinErrores(), err => {
        console.log(err);
        this.conErrores();
      }).finally(() => {
        this.desocupado();
        this.ordenesService.sendCommand({command: "update", sender: "controller"}, this.pantalla)
      })
      return;
    }

    firebase.functions().httpsCallable("addUser")({ password: this.passwordVal.value, company: this.authService.company, user: this.nombre.value }).then(res => {
      datos.uid = res.data.data.uid;
      datos.company = this.authService.company;
      this.pantallaService.crearPantalla(datos).then(pantalla => {
        this.router.navigate(['/pantallas/' + res.data.data.uid]);
        this.sinErrores();
      },
        err => {
          this.pantallaService.borrarPantalla(res.data.data.uid);
          console.log(err);
          this.conErrores();
        });
    }).catch(error => {
      console.log(error);
      this.conErrores();
    }).finally(() => {
      this.desocupado();
      this.ordenesService.sendCommand({command: "update", sender: "controller"}, datos.uid);
    });

  }

  borrarDialog(): void {
    const dialogRef = this.dialog.open(CommonDialog, {
      width: '500px',
      data: { pregunta: "¿Estás seguro de querer borrar la pantalla? Esta acción no se puede revertir" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.borrar();
      }
    });
  }
  
  borrar() {
    this.busy = true;
    this.pantallaService.borrarPantalla(this.pantalla).then(() => {
      firebase.functions().httpsCallable("deleteUser")({ uid: this.model.uid }).then(res => {
        this.router.navigate(['/pantallas']);
        this.sinErrores();
      }).catch(err => {
        console.log(err);
        this.conErrores();
      });
    }).catch(err => {
      console.log(err);
      this.conErrores();
    }).finally(() => this.desocupado());

  }

  muestraBorrar(): boolean {
    return this.pantalla && this.pantalla != "0";
  }

  cerrar() {
    this.router.navigate(['/pantallas']);
  }

  playlists: PlaylistModel[] = [];  
  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {

      this.groupService.grupos().then(t => {
        let aux: string[] = Object.values(t.data());
        for (let i = 0; i < aux[0].length; i++) {
          this.allGroups.push(aux[0][i]);
        }
      })

      this.pantalla = params.get("id");
      if (this.pantalla == "0") {
        this.tituloPantalla = "Crear nueva pantalla";
        this.createForm();
        this.filteredGroups = this.gruposCtrl.valueChanges.pipe(
          startWith(null),
          map((grupo: string | null) => grupo ? this._filter(grupo) : this.allGroups.slice()));
      } else {
        this.pantallaService.pantalla(this.pantalla).then(res => {
          this.model = res.data();
          this.model.programa.forEach(element => {
            let endTime: any = element.end;
            let startTime: any = element.start;
            element.end = new Date(endTime.seconds * 1000);
            element.start = new Date(startTime.seconds * 1000);
          });
          this.updateForm(this.model);
          this.filteredGroups = this.gruposCtrl.valueChanges.pipe(
            startWith(null),
            map((grupo: string | null) => grupo ? this._filter(grupo) : this.allGroups.slice()));
        }, err => console.log("Error, la pantalla no existe")).finally(() => {
          
        });
      }
    });    
  }
}
