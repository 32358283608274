import { Component, Input, OnInit } from '@angular/core';
import { MediaModel } from 'src/app/core/modelos/media.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'src/app/core/app/servicios/media.service';
import { MediaType } from 'src/app/core/enum/media-type';
import { AuthService } from 'src/app/core/auth.service'; 
import { PartialObserver } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'single-media',
  templateUrl: './single-media.component.html',
  styleUrls: ['./single-media.scss']
})
export class SingleMediaComponent implements OnInit {
  imagen: boolean = false;
  @Input() 
  public mediaModel: MediaModel;
  @Input() 
  public hideName: boolean = false;

  public showLoading: boolean = true;
  constructor(public sanitizer: DomSanitizer, public mediaService: MediaService, private auth: AuthService, private router: Router) {

  }

  ngOnInit(): void {
    if(this.mediaModel.tipo == MediaType.Imágen || this.mediaModel.tipo == MediaType.Vídeo){
      let ref = this.mediaService.referenciaCloudStorage("media/" + this.auth.company + "/" + this.mediaModel.thumb).getDownloadURL().subscribe((t: string) => {
        this.mediaModel.publicThumb = t;
        this.showLoading = false;
      }, error => {

      })      
    }
  } 
}
