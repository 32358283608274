import * as firebase from 'firebase';
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { PlaylistProgramada } from '../../modelos/playlist-programada.model';

@Injectable()
export class GruposService {
    constructor(
        public db: AngularFirestore,
        public auth: AuthService
    ) {

    }

    grupos(): Promise<any> {
        debugger;
        return new Promise(resolve => {
            this.db.collection("grupos").doc(this.auth.company).get().subscribe(grupos => {
                resolve(grupos);
            });        
        });
    }

    actualizarGrupos(grupos: string[]): Promise<void> {
        let uniqueArray = [...new Set(grupos)];
        return this.db
            .collection("grupos")
            .doc(this.auth.company)
            .set({ grupos: uniqueArray }, { merge: true });
    }

    contarPantallas(grupo: string) {
        return this.db.collection("pantallas").ref.where('grupos', 'array-contains', grupo).get();
    }

    batchUpdate(grupo: string, playlist: string[], programada: boolean, programa: PlaylistProgramada[]): Promise<any> {
        return firebase.functions().httpsCallable("saveBatchList")({ grupo: grupo, playlist: playlist, programada: programada, programa: programa});
    }
}
