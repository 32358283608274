import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../../core/user.model';
import { PantallaModel } from 'src/app/core/modelos/pantalla.model';
import { PantallaType } from 'src/app/core/enum/pantalla-type';
import { max } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PantallasComponent } from '../pantallas.component';
import { PantallaService } from 'src/app/core/app/servicios/pantalla.service';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { DomSanitizer } from '@angular/platform-browser';
import { OrdenesService } from 'src/app/core/app/servicios/ordenes.service';
import { CommandModel } from 'src/app/core/modelos/command.model';
import { MediaService } from 'src/app/core/app/servicios/media.service';


@Component({
  selector: 'control-pantalla',
  templateUrl: 'pantalla-control.component.html',
  styleUrls: ['pantalla-control.scss']
})
export class PantallaControlComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.toUnsuscribe.unsubscribe();
  }
  PantallaType = PantallaType;
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  toUnsuscribe: any;
  suscripcion: Observable<any>;

  tipoToCss(tipo: PantallaType): string {
    return PantallaType[tipo];
  }

  @Input()
  public pantallaModel: PantallaModel;



  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    private router: Router,
    private pantallaService: PantallaService,
    public sanitizer: DomSanitizer,
    private ordenesService: OrdenesService,
    private mediaService: MediaService
  ) {

  }


  realThumb: string = "";
  ngOnInit(): void {
    this.getOnlineTime();
    this.suscripcion = this.ordenesService.tiempoRealById(this.pantallaModel.id);
    this.toUnsuscribe = this.suscripcion.subscribe(t => {
      if (t && t.payload && t.type == "value") {
        let data = t.payload.data();
        if (t.payload._fromCache) {
          this.refresh();
          return;
        }
        if (data) {
          this.pantallaModel.online = data.online;
          this.ultimaComprobacion = 0;

          if (data.response && data.response.type == "status") {
            if (data.response.response) {
              this.mediaService.referenciaCloudStorageRef("thumbnail_" + data.response.response).getDownloadURL().subscribe(t => {                
                this.realThumb = t;
              });
            }else{
              this.realThumb = ""
            }
          }
        }
      }
    });
  }

  getNumberOfPlaylists(): number {
    if (this.pantallaModel && this.pantallaModel.playlist.length > 0) {
      return this.pantallaModel.playlist.length;
    } else {
      return 0;
    }
  }

  refresh() {
    let orden: any = { command: "status", response: { response: null, type: null }, sender: "controller" }
    this.ordenesService.sendCommand(orden, this.pantallaModel.uid);
  }

  onlineTime: number;
  ultimaComprobacion: number;
  getOnlineTime(): void {
    setInterval(() => {
      this.ultimaComprobacion++;
      this.ultimaComprobacion = Math.floor(Math.min(this.ultimaComprobacion, 300));
      if (this.pantallaModel && this.pantallaModel.online) {
        let a = new Date().getTime()
        let b = new Date((<any>this.pantallaModel.online).seconds * 1000).getTime()
        let time = ((a - b) / 1000);
        if (time < (60 * 5)) {
          this.onlineTime = 0;
          return 0;
        } else {
          this.onlineTime = Math.min(60, Math.floor(time / 60));
          return this.onlineTime;
        }
      } else {
        this.onlineTime = -1;
        return -1;
      }
    }, 1000);
  }

  public editar(): void {
    this.router.navigate(['/pantallas/' + this.pantallaModel.id]);
  }


  save(value) {

  }
}
