import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { PlaylistModel } from '../../modelos/playlist.model';
import { AuthService } from '../../auth.service';

@Injectable()
export class PlaylistService {

    constructor(
        public db: AngularFirestore,
        public auth: AuthService
    ) {

    }

    allPlaylistsCache: any;

    numeroDePlaylists(): number {
        //Todavia no
        return 0;
    }

    playlists() : Promise<any> {
        if(this.allPlaylistsCache){
            return new Promise(resolve =>{
                resolve(this.allPlaylistsCache);
            });
        }
        return new Promise(resolve => {            
            var playlists: any = this.db.collection("playlists", playlists => playlists.where("company", "==", this.auth.company));
            playlists.get().subscribe(pl => {            
                this.allPlaylistsCache = pl;
                resolve(pl);
            });
        });
    }

    borrarPlaylist(id: string): Promise<any> {
        this.allPlaylistsCache = null;
        return this.db
            .collection("playlists")
            .doc(id)
            .delete();
    }

    actualizarPlaylist(id: string, data: PlaylistModel): Promise<void> {
        this.allPlaylistsCache = null;
        data.company = this.auth.company;
        return this.db
            .collection("playlists")
            .doc(id)
            .set(data, { merge: true });
    }

    playlist(id: string): Promise<any> {
        return this.db
            .collection("playlists")
            .doc(id).ref.get();
    }

    crearPlaylist(data: PlaylistModel): Promise<any> {
        this.allPlaylistsCache = null;
        data.company = this.auth.company;
        return this.db
            .collection("playlists")
            .add(data);
    }
}
