import { Component, OnInit } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import { MediaModel } from '../core/modelos/media.model';
import { MediaService } from '../core/app/servicios/media.service';
import { StatsService } from '../core/app/servicios/stats-service';

@Component({
  selector: 'page-pantallas',
  templateUrl: 'medias.component.html',
  styleUrls: ['medias.scss']
})
export class MediasComponent implements OnInit{
  medias: MediaModel[];
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public location : Location,
    public fb: FormBuilder,
    private router: Router,
    private mediaService: MediaService,
    private statsService: StatsService

  ) {

  }

  public busy = true;
  public bytes: number = 0;
  ngOnInit(): void {
    this.statsService.printStorageLeft().then((bytes) => {
      this.bytes = bytes;
    });
    this.mediaService.medias(this.authService.company).then(res => {
      this.medias = res.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        this.busy = false;
        return model;
      });      
    }, err => console.log(err))
  }

  navigateMedia(id){
    this.router.navigate(['/multimedia/' + id]);
  }

  nuevaMedia(){
    this.router.navigate(['/multimedia/0']);
  }
}
