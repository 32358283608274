import { Inject, Component } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';

export interface DialogResult {
    promptEmail: boolean;
    pregunta: string;
    result: boolean;
    error: boolean;
    tipo: string;
    link: string;
}

@Component({
    selector: 'common-dialog',
    templateUrl: 'common.dialog.component.html',
})
export class CommonDialog {
    public email: string;
    constructor(
        public dialogRef: MatDialogRef<CommonDialog>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogResult) { }

    onClick(result: boolean): void {
        this.data.result = result;
        this.dialogRef.close(this.data.result);
    }

    sendMail(result: boolean): void{        
        this.data.result = result;
        this.data.link = this.email;
        this.dialogRef.close(this.data);
    }

    navegar(){
        this.router.navigate(['/' + this.data.tipo + '/' + this.data.link]);
        this.dialogRef.close();
    }

}