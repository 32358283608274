import { Component } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators, AbstractControl, ControlContainer, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  registerForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  formCtrlSub: Subscription;
  company = new FormControl();

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  companyExist: boolean = false;
  createForm() {
    this.registerForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      company: ['', { validators: [Validators.required], asyncValidators: [this.newCompany.bind(this)], updateOn: 'blur' }]
    });
  }

  print(t) {
    console.log(t);
  }

  lastAttempt = new Date().getTime();
  tryAgain = false;
  newCompany(control: AbstractControl) {
    return this.authService.checkCompanyNotTaken(control.value).then((value) => {      
      if (value.data == 200) {
        this.companyExist = true;
        return { companyTaken: true };
      } else {
        this.companyExist = false;
        return null;
      }
    });
  }

  tryFacebookLogin() {
    this.authService.doFacebookLogin()
      .then(res => {
        this.router.navigate(['/user']);
      }, err => console.log(err)
      )
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin()
      .then(res => {
        this.router.navigate(['/user']);
      }, err => console.log(err)
      )
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin()
      .then(res => {
        this.router.navigate(['/user']);
      }, err => console.log(err)
      )
  }

  tryRegister(value) {
    let datos = {user: this.registerForm.get('email').value, password: this.registerForm.get('password').value, company: this.registerForm.get('company').value};
    this.authService.registrar(datos).then((res:any) => {
      console.log(res);
      if(res.data.data.error){      
        this.errorMessage = res.data.data.error;
        this.successMessage = "";
      }else{
        this.errorMessage = "";
        this.successMessage = "Su cuenta ha sido creada, compruebe su correo para verificar su cuenta";        
      }
    }, err => {
      console.log(err);
      this.errorMessage = err.message;
      this.successMessage = "";
    }).catch(err =>{
      console.log(err);
      this.errorMessage = err.message;
      this.successMessage = "";
    })

    // this.authService.doRegister(value)
    //   .then(res => {
    //     console.log(res);
    //     this.errorMessage = "";
    //     this.successMessage = "Su cuenta ha sido creada";
    //   }, err => {
    //     console.log(err);
    //     this.errorMessage = err.message;
    //     this.successMessage = "";
    //   })
  }

}
