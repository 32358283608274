import { Injectable } from "@angular/core";
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class AuthService {

  public company: string;
  public userId: string;
  public admin: boolean;
  public user: any;
  public bytes: number;
  public membership: string;
  public storage: number;
  constructor(
    public afAuth: AngularFireAuth,
    private dbRt: AngularFireDatabase
  ) {

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        if (this.afAuth.auth.currentUser != null) {
          this.userId = this.afAuth.auth.currentUser.uid;
          this.user = this.afAuth.auth.currentUser;
          this.afAuth.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              this.company = idTokenResult.claims.company;
              this.admin = idTokenResult.claims.admin;

              this.dbRt.database.ref(this.company + "/membership").once("value").then(t => {
                this.membership = t.val();
                if (this.membership == "premium") {
                  this.storage = 500;
                } else if (this.membership == "basic") {
                  this.storage = 25;
                } else {
                  this.storage = 0;
                }
              });

            }, err => console.log(err));
        }
      } else {
        // No user is signed in.
      }
    });

  }


  getCurrentUser(): Observable<any> {
    return this.afAuth.authState;
  }

  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
        .signInWithPopup(provider)
        .then(res => {
          resolve(res);
        }, err => {
          console.log(err);
          reject(err);
        })
    })
  }

  registrar(datos){
    return firebase.functions().httpsCallable("addAdminUser")({ password: datos.password, company: datos.company, user:  datos.user });
  }

  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
        .then(res => {
          resolve(res);
        }, err => reject(err))
    })
  }

  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
        .then(res => {
          this.afAuth.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              this.company = idTokenResult.claims.company;
              if (idTokenResult.claims.admin) {
                //Admin
                resolve(res);
              }
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
            });


        }, err => reject(err))
    })
  }

  doLogout() {
    return new Promise((resolve, reject) => {
      if (firebase.auth().currentUser) {
        this.afAuth.auth.signOut();
        resolve();
      }
      else {
        reject();
      }
    });
  }

  invitacion(mail: string) {
    return firebase.functions().httpsCallable("generateInvitation")({ mail: mail }).then((response: any) => {

    });
  }

  checkCompanyNotTaken(company: any): any {
    return firebase.functions().httpsCallable("companyExists")({company: company});
  }




}
