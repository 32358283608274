import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import { ValueModel, ValueModelId } from '../core/modelos/value.model';
import { BillingService } from '../core/app/servicios/billing-service';
import { BillingModel } from '../core/modelos/billing.model';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'page-user',
  templateUrl: 'user.component.html',
  styleUrls: ['user.scss']
})
export class UserComponent implements OnInit {

  suscripcionesDefinition = [
    { "Nombre": "Gratis", "Pantallas": 2, "Almacenamiento": "5mb", "Playlists": 1, "Precio": "Gratis", "Recomendado": "Recomendado para iniciarse y probar el servicio", "ID": null },
    { "Nombre": "Básica", "Pantallas": 4, "Almacenamiento": "50mb", "Playlists": 2, "Precio": "10€ al mes", "Recomendado": "Recomendado para un comercio pequeño", "ID": "plan_G5lygwcUPMuQXb" },
    { "Nombre": "Premium", "Pantallas": 10, "Almacenamiento": "400mb", "Playlists": 8, "Precio": "85€ al mes", "Recomendado": "Recomendado para pequeños comercios con grandes necesidades", "ID": "plan_G5lzSWkoGQNFjy" },    
    { "Nombre": "Ultra", "Pantallas": 50, "Almacenamiento": "400mb", "Playlists": 200, "Precio": "400€ al mes", "Recomendado": "Recomendado para medianas empresas", "ID": "plan_G5m0URig1qAPus" },
    { "Nombre": "Ultra XL", "Pantallas": 100, "Almacenamiento": "800mb", "Playlists": 1000, "Precio": "750€ al mes", "Recomendado": "Recomendado para grandes empresas", "ID": "plan_G5m0oePtSRzX3t" }];
  
  tipos: ValueModelId[] = [{ nombre: "Básica", valor: "Básica" }, { nombre: "Premium", valor: "Premium" }, { nombre: "Premium+", valor: "Premium+" }, { nombre: "Premium++", valor: "Premium++" }, { nombre: "Ultra", valor: "Ultra" }];

  get nombre() {
    return this.paymentMethod.get('nombre');
  }

  get email() {
    return this.paymentMethod.get('email');
  }

  get descripcion() {
    return this.paymentMethod.get('descripcion');
  }  


  profileForm: FormGroup;
  paymentMethod: FormGroup;
  user: FirebaseUserModel = new FirebaseUserModel();
  billing: BillingModel;
  busy: boolean = true;
  ready: boolean = false;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private location: Location,
    private fb: FormBuilder,
    private billingService: BillingService
  ) {
    this.paymentMethod = this.fb.group({
      email: [null, Validators.required],
      nombre: [null, Validators.required],
      descripcion: [null, Validators.required]
    });
  }

  private sessionId: string;
  ngOnInit(): void {
    this.sessionId = this.route.snapshot.queryParamMap.get("session_id")
    this.route.queryParamMap.subscribe(queryParams => {
      this.sessionId = queryParams.get("session_id")
    });

    if(environment.production){
      this.suscripcionesDefinition = [
        { "Nombre": "Gratis", "Pantallas": 2, "Almacenamiento": "5mb", "Playlists": 1, "Precio": "Gratis", "Recomendado": "Recomendado para iniciarse y probar el servicio", "ID": null },
        { "Nombre": "Básica", "Pantallas": 4, "Almacenamiento": "50mb", "Playlists": 2, "Precio": "10€ al mes", "Recomendado": "Recomendado para un comercio pequeño", "ID": "plan_G6YYuWcYkiZhtG" },
        { "Nombre": "Premium", "Pantallas": 10, "Almacenamiento": "400mb", "Playlists": 8, "Precio": "85€ al mes", "Recomendado": "Recomendado para pequeños comercios con grandes necesidades", "ID": "plan_G6YY4SxWNmSm0I " },    
        { "Nombre": "Ultra", "Pantallas": 50, "Almacenamiento": "400mb", "Playlists": 200, "Precio": "400€ al mes", "Recomendado": "Recomendado para medianas empresas", "ID": "plan_G6YY07tf03OfxW" },
        { "Nombre": "Ultra XL", "Pantallas": 100, "Almacenamiento": "800mb", "Playlists": 1000, "Precio": "750€ al mes", "Recomendado": "Recomendado para grandes empresas", "ID": "plan_G6YYObM3NgRycs" }];
    }

    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.user = data;
        this.createForm(null, this.user.name);
      }
      this.getBilling();
      this.getPayments();
    });
  }

  subscribe(plan: string) {
    this.busy = true;
    this.billingService.subscribe({ planId: plan }).then((t: any) => {
      this.getResponseFromStripe(t.data.id);
    }).finally(() => {
      this.busy = false;
    })
  }

  unsubscribe(plan: string) {
    this.busy = true;
    this.billingService.unsubscribe(this.activeSuscriptionId).then((t: any) => {
      this.busy = false;
      this.getBilling();
    }).finally(() => {
      this.busy = false;
    });
  }

  getResponseFromStripe(id) {

    this.billingService.stripe.redirectToCheckout({
      // Make the id field from the Checkout Session creation API response
      // available to this file, so you can provide it as parameter here
      // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
      sessionId: id
    }).then(function (result) {
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `result.error.message`.
    });
  }

  public cardData = undefined;
  public activeSuscriptionId;
  public activeSuscription;
  public activePlan;
  private getBilling() {
    this.busy = true;
    this.billingService.activeSuscription().then(t => {
      let suscription = t.val();
      if (suscription && suscription.plan) {
        this.activeSuscriptionId = suscription.subscription;
        this.activePlan = suscription.plan;
        this.activeSuscription = suscription.plan.id;
      } else {
        this.activeSuscription = null;
      }
    }).finally(() => {
      this.ready = true;
      this.busy = false;
    });
  }

  nextRenew: any;
  subscriptionSince: any;
  private getPayments(){
    this.billingService.getNextRenewals().then(t => {
      this.nextRenew = new Date(t.data.endDate * 1000).toLocaleDateString();
      this.subscriptionSince = new Date(t.data.created * 1000).toLocaleDateString();
    });
  }

  createForm(tipo, name) {
    this.profileForm = this.fb.group({
      tipo: [tipo, Validators.required],
      nombre: [name, Validators.required]
    });
  }

  save(value) {
    this.userService.updateCurrentUser(value)
      .then(res => {
        console.log(res);
      }, err => console.log(err))
  }

  logout() {
    this.authService.doLogout()
      .then((res) => {
        this.location.back();
      }, (error) => {
        console.log("Logout error", error);
      });
  }
}
