import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { ValueModel } from 'src/app/core/modelos/value.model';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';
import { AuthService } from 'src/app/core/auth.service';
import * as firebase from 'firebase';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonDialog } from 'src/app/core/commondialog/common.dialog.component';
import { MediaService } from 'src/app/core/app/servicios/media.service';
import { MediaModel } from 'src/app/core/modelos/media.model';
import { PlaylistType } from 'src/app/core/enum/playlist-type';
import { MediaType } from 'src/app/core/enum/media-type';
import { DomSanitizer } from '@angular/platform-browser';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MediaWrapperModel } from 'src/app/core/modelos/media-wrapper.model';

@Component({
  selector: 'editor-playlist',
  templateUrl: 'editor-playlist.component.html',
  styleUrls: ['editor-playlist.scss']
})
export class EditorPlaylistComponent implements OnInit {
  medias: MediaModel[];  
  playlist: string;
  busy: boolean = false;
  listForm: FormGroup;
  tituloPlaylist: string;
  ready: boolean = false;
  model: PlaylistModel = {nombre: "", descripcion: "Playlist " + (new Date()).toLocaleDateString('en-GB'), tipo: PlaylistType.Orgánica, company: this.authService.company, geopos: null, id: null, repetir: 1, prioridad: 1, list: []};
  tiposPlaylist: ValueModel[] = [{ valor: 0, nombre: "Orgánica" }, { valor: 1, nombre: "Pago"}];

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    public playlistService: PlaylistService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private mediaService: MediaService,
    public sanitizer: DomSanitizer
  ) {

  }

  delete(media: MediaWrapperModel){
    this.model.list = this.model.list.filter(t => t != media);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model.list, event.previousIndex, event.currentIndex);
  }

  addToList(x){
    if(x.tipo == MediaType.Imágen || x.tipo == MediaType.Vídeo){
      let ref = this.mediaService.referenciaCloudStorage("media/" + this.authService.company + "/" + x.thumb).getDownloadURL().subscribe((t: string) => {        
        x.publicThumb = t;
      }, error => {

      })
      
    }
    if(x.tipo == MediaType.Vídeo){
      this.model.list.push({company: this.authService.company, duracion: 999, media: x, repeticiones: 1, id: null, publicUrl: null});
    }else{
      this.model.list.push({company: this.authService.company, duracion: 10, media: x, repeticiones: 1, id: null, publicUrl: null});
    }
  }

  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cerrar", { politeness: "assertive", horizontalPosition: "center", verticalPosition: "top", duration: 3000 })
  }

  desocupado() {
    this.busy = false;
  }

  conErrores() {
    this.openSnackBar("Se produjo un error al intentar realizar la operación")
  }

  sinErrores() {
    this.openSnackBar("Operación completada")
  }

  get nombre() { return this.listForm.get('nombre'); }
  get tipo() { return this.listForm.get('tipo'); }
  get repetir() { return this.listForm.get('repetir'); }
  get prioridad() { return this.listForm.get('prioridad'); }
  get descripcion() { return this.listForm.get('descripcion'); }

  createForm() {
    this.listForm = this.fb.group({
      nombre: ['', Validators.required],
      tipo: [''],
      descripcion: ['', Validators.maxLength(80)],
      // prioridad: ['', [Validators.required]]
      prioridad: ['', []]
    });
    this.ready = true;
  }

  updateForm(playlist) {
    this.listForm = this.fb.group({
      nombre: [playlist.nombre, Validators.required],
      descripcion: [playlist.descripcion, Validators.maxLength(80)],
      tipo: [playlist.tipo],
      prioridad: [playlist.prioridad, Validators.required]
    });
    this.ready = true;
    this.tituloPlaylist = "Editando la playlist: " + playlist.nombre.toLowerCase();
  }

  allMediaValid(): boolean{
    for(let i = 0; i < this.model.list.length; i++){
      let toParse: any = this.model.list[i].repeticiones;
      let n = Number(toParse);
      if(isNaN(n)){
        return false;
      }

      if(n <= 0){
        return false;
      }
      toParse = this.model.list[i].duracion;
      n = Number(toParse);
      if(isNaN(n)){
        return false;
      }
      if(n <= 0){
        return false;
      }
    }
    return true;
  }

  valid(): boolean{
    return this.listForm && this.listForm.status == 'VALID' && this.allMediaValid();
  }

  salvar() {
    let datos = <PlaylistModel>{ nombre: this.model.nombre, descripcion: this.model.descripcion, tipo: this.model.tipo, geopos: this.model.geopos, list: this.model.list, prioridad: this.model.prioridad, repetir: this.model.repetir };
    this.busy = true;
    if (this.playlist != "0") {
      this.playlistService.actualizarPlaylist(this.playlist, datos).then(() => this.sinErrores(), err => {
        console.log(err);
        this.conErrores();
      }).finally(() => this.desocupado())
      return;
    }

    this.playlistService.crearPlaylist(datos).then(playlist => {
      this.router.navigate(['/playlists/' + playlist.id]);
      this.sinErrores();
    },
      err => {
        this.playlistService.borrarPlaylist(this.model.id);
        console.log(err);
        this.conErrores();
      });

  }

  borrarDialog(): void {
    const dialogRef = this.dialog.open(CommonDialog, {
      width: '500px',
      data: { pregunta: "¿Estás seguro de querer borrar la playlist? Esta acción no se puede revertir" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.borrar();
      }
    });
  }

  borrar() {
    this.busy = true;
    this.playlistService.borrarPlaylist(this.playlist).then(() => {
      this.sinErrores();
    }).catch(err => {
      console.log(err);
      this.conErrores();
    }).finally(() => this.desocupado());

  }

  muestraBorrar(): boolean {
    return this.playlist && this.playlist != "0";
  }

  cerrar() {
    this.router.navigate(['/playlists']);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.playlist = params.get("id");
      if (this.playlist == "0") {
        this.tituloPlaylist = "Crear nueva playlist";
        this.createForm();        
      } else {
        this.playlistService.playlist(this.playlist).then(res => {
          this.model = res.data();
          this.updateForm(this.model);
        }, err => console.log("Error, la playlist no existe"))
      }
    });
    this.mediaService.medias(this.authService.company).then(res => {
      this.medias = res.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        return model;
      });      
    }, err => console.log(err))
  }
}
