export class FirebaseUserModel {
  image: string;
  name: string;
  provider: string;
  customClaims: any;

  constructor(){
    this.image = "";
    this.name = "";
    this.provider = "";
    this.customClaims = undefined;
  }
}
