// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyCi928hBJlKRreeC9PxVzigDA7_GCuWsLw",
    authDomain: "screenads-prod.firebaseapp.com",
    databaseURL: "https://screenads-prod.firebaseio.com",
    projectId: "screenads-prod",
    storageBucket: "screenads-prod.appspot.com",
    messagingSenderId: "904089867680",
    appId: "1:904089867680:web:b56bbe60044cc48a0a80c5",
    measurementId: "G-XXHC0861BK"
  }
};