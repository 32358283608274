import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GruposService } from '../core/app/servicios/grupos-service';
import { PlaylistService } from '../core/app/servicios/playlist.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PantallaModel } from '../core/modelos/pantalla.model';

@Component({
  selector: 'grupos',
  templateUrl: 'grupos.component.html',
  styleUrls: ['grupos.scss']
})
export class GruposComponent implements OnInit {

  info: MatSnackBarConfig = {horizontalPosition: "center", verticalPosition: "top", duration: 3000};
  loginForm: FormGroup;
  errorMessage: string = '';
  allGroups: string[] = [];
  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private gruposService: GruposService,
    private playlistService: PlaylistService
  ) {
    this.ready = true;    
  }

  busy: boolean = false;
  grupo: any;
  playlists: [];
  ngOnInit(): void {
    this.gruposService.grupos().then(t => {
      let aux: string[] = Object.values(t.data());
        for (let i = 0; i < aux[0].length; i++) {
          this.allGroups.push(aux[0][i]);
        }
      });
      this.playlistService.playlists().then(t => {
        this.playlists = t.docs.map(t => {
          let item = t.data();
          item.uid = t.id;
          return item;
        }); 
      })
  }

  selectedScreens = [];
  select(grupo: string){
    this.gruposService.contarPantallas(grupo).then(t => {
      this.selectedScreens = t.docs.map(t => t.data()); 
    });
  }

  model: PantallaModel;
  ready: boolean = false;
  salvar() {
    this.busy = true;
    let asignadas = this.model.playlist;
    this.gruposService.batchUpdate("General", asignadas, this.model.programada, this.model.programa).then((response: any) => {
      this.busy = false;
    });
  }

  cerrar() {
    this.router.navigate(['/']);
  }

  public navigate(to: string): void {
    this.router.navigate(['/pantallas/' + to]);
  }

  playlistsAsignadas = [];
  addToList(x) {
    this.playlistsAsignadas.push(x);
  }

  delete(x) {
    this.playlistsAsignadas = this.playlistsAsignadas.filter(t => t != x);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.playlistsAsignadas, event.previousIndex, event.currentIndex);
  }
}