import { FormsModule } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';
import { Subject } from 'rxjs';
import { CalendarEvent, WeekDay } from 'calendar-utils';
import { CalendarEventTimesChangedEvent } from 'angular-calendar';
import { PantallaService } from 'src/app/core/app/servicios/pantalla.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { GruposService } from 'src/app/core/app/servicios/grupos-service';
import { OrdenesService } from 'src/app/core/app/servicios/ordenes.service';
import { PantallaModel } from 'src/app/core/modelos/pantalla.model';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { ScheduledDialog } from '../scheduled/dialog/scheduled.dialog.component';
import { PlaylistProgramada } from 'src/app/core/modelos/playlist-programada.model';

@Component({
  selector: 'playlist-creator',
  templateUrl: 'playlist-creator.component.html',
  styleUrls: ['playlist-creator.scss']
})
export class PlaylistCreatorComponent implements OnInit, OnDestroy {

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    public pantallaService: PantallaService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private playlistService: PlaylistService,
    private groupService: GruposService,
    private ordenesService: OrdenesService
  ) {

  }

  playlists: PlaylistModel[] = [];
  playlistsAsignadas: PlaylistModel[] = [];

  ngOnInit(): void {
    if (!this.model) {
      this.model = <PantallaModel>{};
      this.model.playlist = [];      
    }
    this.refresh.next();
    this.getPlaylists();
  }
  ngOnDestroy(): void {

  }

  addToList(x) {
    this.playlistsAsignadas.push(x);
    this.model.playlist = this.playlistsAsignadas.map(t => t.id);
  }

  delete(x) {
    this.playlistsAsignadas = this.playlistsAsignadas.filter(t => t != x);
    this.model.playlist = this.playlistsAsignadas.map(t => t.id);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.playlistsAsignadas, event.previousIndex, event.currentIndex);
    this.model.playlist = this.playlistsAsignadas.map(t => t.id);
  }

  niceColorsSecondary = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff', '#000000']
  niceColors = ['#ffffff', '#ffffff', '#000000', '#ffffff', '#ffffff', '#ffffff', '#000000', '#ffffff', '#000000', '#000000', '#ffffff', '#000000', '#ffffff', '#000000', '#ffffff', '#000000', '#ffffff', '#000000', '#000000', '#000000', '#ffffff', '#000000']
  refresh: Subject<any> = new Subject();
  initial: Date = new Date(10058923031986);
  events: PlaylistProgramada[] = [];

  public addPlaylistEvent(date: any) {
    let r = Math.floor(Math.random() * this.niceColors.length);
    let ev = { start: date, end: new Date(date.getTime() + (30 * 60000)), title: "(sin nombre)", color: { primary: this.niceColors[r], secondary: this.niceColorsSecondary[r] }, resizable: { beforeStart: true, afterEnd: true }, draggable: true, new: true, playlist: null };
    
    
    this.infoEventClicked(ev);
  }

  selectedEvent: PlaylistProgramada = {} as PlaylistProgramada;  

  infoEventClicked(event: any){
    const dialogRef = this.dialog.open(ScheduledDialog, {
      width: '100%',
      minWidth: '500px',
      maxWidth: '1920px',
      panelClass: 'center-dialog',
      data: { promptEmail: true, event: event, selected: event.color.primary }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(!result){
        this.refresh.next();
        return;
      }

      if(result.borrar){
        const index = this.model.programa.indexOf(result.event, 0);
        if (index > -1) {
          this.model.programa.splice(index, 1);
        }
        this.refresh.next();
        return;
      }

      result.event.title = result.playlist.nombre;
      result.event.playlist = result.playlist.id;
      result.event.color = { primary: result.selected, secondary: result.selected };
      if(event.new){
        if(!this.model.programa){
          this.model.programa = [];  
        }
        this.model.programa.push(result.event);
        result.event.new = false;
      }
      this.refresh.next();
    });
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd
  }: CalendarEventTimesChangedEvent): void {
    this.model.programa = this.model.programa.map((iEvent: PlaylistProgramada) => {
      if (iEvent === event) {
        return <PlaylistProgramada>{
          ...event,
          start: new Date(newStart),
          end: new Date(newEnd)
        };
      }
      return iEvent;
    });
  }

  @Input() model: PantallaModel;
  @Output() modelChange = new EventEmitter();

  private getPlaylists() {
    let o = 0;
    this.playlistService.playlists().then(t => {
      this.playlists = t.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        model.color = this.niceColors[o];
        o = (o + 1) % this.niceColors.length;
        return model;
      });
      if (!this.model) {
        return;
      }
      for (let i = 0; i < this.model.playlist.length; i++) {
        if (this.playlists.some(t => t.id == this.model.playlist[i])) {
          this.playlistsAsignadas.push(this.playlists.filter(t => t.id == this.model.playlist[i])[0]);
        }
      }
    });
  }

  @Input() days: WeekDay[];
  @Input() locale: string;
  @Input() customTemplate: TemplateRef<any>;
  @Output()
  dayHeaderClicked: EventEmitter<{ day: WeekDay }> = new EventEmitter<{
    day: WeekDay;
  }>();

  @Output()
  eventDropped: EventEmitter<{
    event: CalendarEvent;
    newStart: Date;
  }> = new EventEmitter<{ event: CalendarEvent; newStart: Date }>();

  trackByWeekDayHeaderDate = (index: number, day: WeekDay) =>
    day.date.toISOString();
}
