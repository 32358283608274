import { Component, Inject } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router, Params, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StatsService } from '../core/app/servicios/stats-service';
import { PantallaService } from '../core/app/servicios/pantalla.service';
import { PantallaModel } from '../core/modelos/pantalla.model';
import { PlaylistModel } from '../core/modelos/playlist.model';
import { PlaylistService } from '../core/app/servicios/playlist.service';
import { MediaService } from '../core/app/servicios/media.service';

@Component({
  selector: 'dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.scss']
})
export class DashboardComponent {
  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private statsService: StatsService,
    private pantallaService: PantallaService,
    private playlistService: PlaylistService,
    private mediaService: MediaService
  ) {

  }

  public nPantallas = 0;
  public nHoras = 0;
  public nPlaylists = 0;
  public online = 0;
  public offline = 0;

  ngOnInit(): void {
    let date = new Date();
    let time = date.getTime() / 1000;
    this.pantallaService.pantallas(this.authService.company).then(res => {
      let pantallas = res.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        if (model.online && model.online.seconds) {
          if (model.online.seconds - time < (60 * 30)) {
            this.online++;
          } else {
            this.offline--;
          }
        }
        return model;
      });
      this.pantallaChartData[0].data = [this.offline, this.online];
      this.nPantallas = res.docs.length;
      this.buildHoursPerScreen(pantallas);
      this.buildPeoplePerPerHourPerWeek(pantallas);
      this.playlistService.playlists().then(t => {
        this.nPlaylists = t.docs.length;
      });
    }, err => console.log(err));

    this.statsService.printStorageLeft().then(t => {
      let arr = [];
      arr.push(parseFloat(t).toFixed(2));
      this.statsService.getTotalStorage().then(d => {
        arr.push((d - t).toFixed(2));
        arr = arr.reverse();
        this.spaceChartData[0].data = arr;
      });
    });

    this.mediaService.getCounters().then(countersRef => {
      let counters = countersRef.data();
      if(!counters){
        this.mediaChartData[0].data = [0, 0, 0]
        return;
      }
      if (counters.htmls) {
        this.totalHtml = counters.htmls;
      }
      if (counters.images) {
        this.totalImages = counters.images;
      }
      if (counters.videos) {
        this.totalVideos = counters.videos;
      }
      this.mediaChartData[0].data = [this.totalImages, counters.videos, this.totalHtml]
    });


  }

  private totalHtml = 0;
  private totalVideos = 0;
  private totalImages = 0;


  buildWeeks(labels: any[]) {
    for (let i = 0; i < 10; i++) {
      labels.push("Semana " + (this.statsService.weekIntoYear() - i));
    }
    labels = labels.reverse();
  }

  private buildPeoplePerPerHourPerWeek(pantallas: PantallaModel[]) {
    pantallas.forEach((element: PantallaModel) => {
      let promise = this.statsService.getPeoplePerHourPerWeek(element.uid);
      promise.then(t => {
        let newArr = Array(10);
        for (let l = 0; l < 10; l++) {
          if (newArr[l]) {
            newArr[l] += t[l]
          } else {
            newArr[l] = t[l]
          }
        }
        this.peopleChartData[0].data = newArr;
      });
    });
    this.buildWeeks(this.peopleChartLabels);
  }


  private buildHoursPerScreen(pantallas: PantallaModel[]) {
    pantallas.forEach((element: PantallaModel) => {
      let promise = this.statsService.getHoursPerWeek(element.uid);
      promise.then(t => {
        let newArr = Array(10);
        for (let l = 0; l < 10; l++) {
          if (newArr[l]) {
            newArr[l] += t[l]
          } else {
            newArr[l] = t[l]
          }
        }
        this.hoursChartData[0].data = newArr;
      });
    });
    this.buildWeeks(this.hoursChartLabels);
  }


  public spaceChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public spaceChartLabels = [
    'Espacio usado',
    'Espacio libre'
  ];

  public spaceChartData = [
    {
      data: [0, 0],
      backgroundColor: ['#ff6961', '#90ee90', '#99badd']
    }
  ];

  public mediaChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public mediaChartLabels = [
    'Imágenes',
    'Vídeos',
    'Html'
  ];

  public mediaChartData = [
    {
      data: [30, 8, 4],
      backgroundColor: ['#f0b476', '#90ee90', '#99badd']
    }

  ];

  public pantallaChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  public pantallaChartLabels = [
    'Online',
    'Offline'
  ];

  public pantallaChartData = [
    {
      data: [4, 2],
      backgroundColor: ['#90ee90', '#f0b476']
    }

  ];

  public hoursChartData = [
    {
      data: Array(10),
      label: "Horas",
      backgroundColor: '#90ee90',
      borderColor: '#90ee90',
      hoverBackgroundColor: '#A0FeA0'
    }
  ]

  public hoursChartLabels = [];
  public hoursChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };


  public peopleChartData = [
    {
      data: Array(10),
      label: "Horas",
      backgroundColor: '#99badd',
      borderColor: '#99badd',
      hoverBackgroundColor: '#A9CaEd'
    }
  ]

  public peopleChartLabels = [];
  public peopleChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };
}  
