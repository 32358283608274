import { Routes } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { PantallasComponent } from './pantallas/pantallas.component';
import { EditorPantallaComponent } from './pantallas/pantalla/editor-pantalla.component';
import { EditorMediaComponent } from './media/control/editor-media.component';
import { MediasComponent } from './media/medias.component';
import { PlaylistsComponent } from './playlists/playlists.component';
import { EditorPlaylistComponent } from './playlists/playlist/editor-playlist.component';
import { SimulacionComponent } from './simulacion/simulacion.component';
import { GruposComponent } from './grupos/grupos.component';
import { CustomizeComponent } from './customize/customize.component';

export const rootRouterConfig: Routes = [
  { path: '', component: DashboardComponent, pathMatch: 'full', resolve: { data: DashboardResolver}, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'pantallas', component: PantallasComponent, canActivate: [AuthGuard] },  
  { path: 'pantallas/:id', component: EditorPantallaComponent, canActivate: [AuthGuard] },  
  { path: 'multimedia', component: MediasComponent, canActivate: [AuthGuard] },
  { path: 'multimedia/:id', component: EditorMediaComponent, canActivate: [AuthGuard] },
  { path: 'playlists', component: PlaylistsComponent, canActivate: [AuthGuard] },
  { path: 'playlists/:id', component: EditorPlaylistComponent, canActivate: [AuthGuard] },
  { path: 'grupos', component: GruposComponent, canActivate: [AuthGuard] },
  { path: 'personalizar', component: CustomizeComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'user', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'user/:success', component: UserComponent,  resolve: { data: UserResolver}},
  { path: 'simulacion', component: SimulacionComponent}
];
