import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UserService } from '../../core/user.service';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../../core/user.model';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';
import { PlaylistType } from 'src/app/core/enum/playlist-type';
import { ValueModel } from 'src/app/core/modelos/value.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from 'src/app/core/app/servicios/media.service';
import { take } from 'rxjs/operators';
import { timer, interval, Subscription, Observable } from 'rxjs';

@Component({
  selector: 'control-playlist',
  templateUrl: 'playlist-control.component.html',
  styleUrls: ['playlist-control.scss']
})
export class PlaylistControlComponent implements OnInit, OnDestroy {

  PlaylistType = PlaylistType;
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  play: boolean = false;
  tipos: ValueModel[] = [{ nombre: "Orgánica", valor: 0 }, { nombre: "De pago", valor: 1 }]
  pause: string;
  busy: boolean = true;

  @Input()
  public playlistModel: PlaylistModel;

  @Input()
  public noEdit: boolean = false;

  @Input()
  public preview: boolean = true;

  @Input()
  public sinBorde: boolean = false;

  public subscribe: Subscription;
  public source: Observable<any>;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    private router: Router,
    public sanitizer: DomSanitizer,
    private mediaService: MediaService
  ) {

  }

  public allLoaded = 0;
  ngOnInit(): void {
    for (let i = 0; i < this.playlistModel.list.length; i++) {
      let val = this.playlistModel.list[i].media.ref;
      if (this.playlistModel.list[i].media.tipo != 2) {
        this.mediaService.referenciaCloudStorage("media/" + this.authService.company + "/thumbnail_" + this.playlistModel.list[i].media.ref).getDownloadURL().subscribe(function (r, cach, t) {
          cach[r] = t;
          this.allLoaded++;
        }.bind(this, val, this.cacheThumb));
      }else{
        this.cacheThumb[val] = null;
        this.allLoaded++;
      }
    }

    if (this.playlistModel.list.length != 0) {
      this.noMedia = false;
      this.source = interval(1000);
      this.subscribe = this.source.subscribe(val => this.incIter());
    } else {
      this.noMedia = true;
    }
  }

  private incIter(): void {
    this.iter = (this.iter + 1) % this.playlistModel.list.length;
    this.getPreview();
  }

  public noMedia: boolean = true;

  ngOnDestroy(): void {
    this.subscribe.unsubscribe();
  }

  public editar(): void {
    this.router.navigate(['/playlists/' + this.playlistModel.id]);
  }

  public getThumb(): string {
    return this.actualThumb;
  }

  private cacheThumb: any = {};
  private iter: number = 0;
  public actualThumb: any;
  public getPreview(): void {
    let val = this.playlistModel.list[this.iter].media.ref;
    this.actualThumb = this.cacheThumb[val];
  }


  save(value) {

  }
}
