import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { PantallaModel } from '../../modelos/pantalla.model';
import { AuthService } from '../../auth.service';
import { auth } from 'firebase';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class PantallaService {

    sendCommand(command: string, id: string) {
        return this.db
            .collection("pantallas")
            .doc(id + "_status")
            .set({ command: command }, { merge: true });
    }

    constructor(
        public db: AngularFirestore,
        private authService: AuthService
    ) {

    }

    numeroDePantallas(): number {
        //Todavia no
        return 0;
    }

    miPantalla(): Observable<any> {
        var pantallas = this.db.collection("pantallas").doc(this.authService.userId).get();
        return pantallas;
    }

    tiempoReal(): Observable<any> {
        var pantallas = this.db.collection("pantallas", pantallas => pantallas.where("uid", "==", this.authService.userId)).snapshotChanges();
        return pantallas;
    }

    tiempoRealStatus(): Observable<any> {
        var pantallas = this.db.collection("pantallas").doc(this.authService.userId + "_status").snapshotChanges();
        return pantallas;
    }

    tiempoRealById(id: string): Observable<any> {
        var pantallas = this.db.collection("pantallas").doc(id).snapshotChanges();
        return pantallas;
    }

    pantallaCache = null;
    pantallas(company: string): Promise<any> {
        if (this.pantallaCache) {
            return new Promise(resolve => {
                resolve(this.pantallaCache);
            });
        }
        return new Promise(resolve => {
            this.db.collection("pantallas", pantallas => pantallas.where("company", "==", company)).get().subscribe(pl => {
                this.pantallaCache = pl;
                resolve(pl);
            });
        });
    }

    borrarPantalla(id: string): Promise<any> {
        this.pantallaCache = null;
        return this.db
            .collection("pantallas")
            .doc(id)
            .delete();
    }

    livenessPantalla(id: string): Promise<void> {
        let d = new Date();
        return this.db
            .collection("pantallas")
            .doc(id)
            .set({ online: d }, { merge: true });
    }

    actualizarPantalla(id: string, data: PantallaModel): Promise<void> {
        this.pantallaCache = null;
        data.company = this.authService.company;
        return this.db
            .collection("pantallas")
            .doc(id)
            .set(data, { merge: true });
    }

    pantalla(id: string): Promise<any> {
        return this.db
            .collection("pantallas")
            .doc(id).ref.get();
    }

    crearPantalla(data: PantallaModel): Promise<any> {
        this.pantallaCache = null;
        data.company = this.authService.company;
        return this.db
            .collection("pantallas")
            .doc(data.uid).set(data);
    }
}
