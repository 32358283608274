import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValueModel } from '../../core/modelos/value.model';
import { MediaModel } from '../../core/modelos/media.model';
import { AuthService } from '../../core/auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { MediaService } from '../../core/app/servicios/media.service';
import { CommonDialog } from '../../core/commondialog/common.dialog.component';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { auth } from 'firebase';
import { StatsService } from 'src/app/core/app/servicios/stats-service';

@Component({
  selector: 'editor-media',
  templateUrl: './editor-media.component.html',
  styleUrls: ['./editor-media.scss']
})
export class EditorMediaComponent implements OnInit {
  busy: boolean = false;
  model: MediaModel;
  nuevaMediaForm: FormGroup;
  ready: boolean = false;
  media: any;
  tituloMedia: string;
  tiposMedia: ValueModel[] = [{ valor: 0, nombre: "Imágen" }, { valor: 1, nombre: "Vídeo" }, { valor: 4, nombre: "HTML" }];

  @ViewChild("iframe", null) el: ElementRef;

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private mediaService: MediaService,
    private statsService: StatsService
  ) {

  }

  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cerrar", { politeness: "assertive", horizontalPosition: "center", verticalPosition: "top", duration: 3000 })
  }

  desocupado() {
    this.busy = false;
  }

  conErrores() {
    this.openSnackBar("Se produjo un error al intentar realizar la operación")
  }

  sinErrores() {
    this.openSnackBar("Operación completada")
  }

  get nombre() { return this.nuevaMediaForm.get('nombre'); }
  get tipo() { return this.nuevaMediaForm.get('tipo'); }
  get descripcion() { return this.nuevaMediaForm.get('descripcion'); }

  createForm() {
    this.nuevaMediaForm = this.fb.group({
      nombre: ['', Validators.required],
      descripcion: ['', Validators.maxLength(80)],
      tipo: ['', Validators.required]
    });
    this.ready = true;
  }

  isImage(str): boolean {
    return str.toLowerCase().endsWith("jpg") || str.toLowerCase().endsWith("jpeg") || str.toLowerCase().endsWith("gif") || str.toLowerCase().endsWith("bmp") || str.toLowerCase().endsWith("png");
  }

  isVideo(str): boolean {
    return str.toLowerCase().endsWith("avi") || str.toLowerCase().endsWith("mpg") || str.toLowerCase().endsWith("mpeg") || str.toLowerCase().endsWith("mp4") || str.toLowerCase().endsWith("mov");
  }

  isHtml(str): boolean {
    return str.toLowerCase().endsWith("html");
  }

  structFile: any;
  selectedFile: string;
  thumb: string;
  event: any;
  onSelectedFilesChanged(event: any) {
    if (event) {
      if (!this.nombre.value) {
        this.nombre.setValue(event[0].name);
      }
      this.event = event[0];
      this.selectedFile = event[0].name;
      this.structFile = event[0];
      if (this.isImage(this.selectedFile)) {
        this.thumb = URL.createObjectURL(event[0]);
        this.tipo.setValue(0);
      } else if (this.isVideo(this.selectedFile)) {
        this.thumb = URL.createObjectURL(event[0]);
        this.tipo.setValue(1);
      } else if (this.isHtml(this.selectedFile)) {
        this.tipo.setValue(2);
        const reader = new FileReader();
        reader.readAsText(event[0]);
        reader.onload = () => {
          this.thumb = reader.result.toString();
          this.el.nativeElement.contentWindow.document.write(this.thumb);
        };
      }
    } else {
      this.thumb = undefined;
      this.selectedFile = null;
      this.structFile = null;
    }
  }

  updateForm(media) {
    this.nuevaMediaForm = this.fb.group({
      nombre: [media.nombre, Validators.required],
      descripcion: [media.descripcion, Validators.maxLength(80)],
      tipo: [media.tipo, Validators.required]
    });
    this.nombre.disable();
    this.tipo.disable();
    this.ready = true;
    this.model = media;
    this.tituloMedia = "Editando: " + media.nombre.toLowerCase();
    let ref = this.mediaService.referenciaCloudStorage("media/" + this.authService.company + "/" + media.ref);
    ref.getDownloadURL().subscribe(t => {
      if (this.model.tipo == 0 || this.model.tipo == 1) {
        this.thumb = t;
      } else if (this.model.tipo == 2) {
        var request = new XMLHttpRequest();
        request.open('GET', t, true);
        request.send(null);
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {            
            this.thumb = request.responseText;
            this.el.nativeElement.contentWindow.document.write(this.thumb);
          }
        }

      }
    });
  }

  salvar() {
    let ref = "";
    if (this.selectedFile) {
      let parts = this.selectedFile.split(".");
      ref = this.mediaService.newGuid() + '.' + parts[parts.length - 1];
    } else {
      ref = this.model.ref;
    }
    let datos = <MediaModel>{ nombre: this.nombre.value, descripcion: this.descripcion.value, tipo: this.tipo.value, ref: ref, thumb: "thumbnail_" + ref, company: this.authService.company };
    this.busy = true;
    if (this.media != "0") {
      this.mediaService.actualizarMedia(this.media, datos).then(() => this.sinErrores(), err => {
        console.log(err);
        this.conErrores();
      }).finally(() => {
        this.desocupado();        
      })
      return;
    }
    let path = "media/" + datos.company + '/' + ref;
    let refMedia = this.mediaService.referenciaCloudStorage(path);
    let refMediaThumb = this.mediaService.referenciaCloudStorage("media/" + datos.company + '/thumbnail_' + ref);

    let task = this.mediaService.reauthenticateWithTokenFor(path);
    task.then(t => {
      this.mediaService.crearMedia(datos).then(media => {
        let uploading = this.mediaService.tareaCloudStorage(path, this.structFile);
        uploading.then(() => { this.sinErrores(); this.statsService.freshness = null }).catch(err => {
          refMedia.delete();
          refMediaThumb.delete();
        });
      },
        err => {
          console.log(err);
          this.conErrores();
        });
    }, error => {
      this.openSnackBar("Se produjo un error al intentar realizar la operación");
      console.log(error);
    }).finally(() => {
      this.desocupado();
      this.router.navigate(['/multimedia']);
    });
  }

  borrarDialog(): void {
    const dialogRef = this.dialog.open(CommonDialog, {
      width: '500px',
      data: { pregunta: "¿Estás seguro de querer borrar la media? Esta acción no se puede revertir" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        this.mediaService.encontrarMediaEnPlaylist(this.model.id).then(t => {
          if (t.empty) {
            this.borrar();
            return;
          }
          let docs = t.docs;
          for (let i = 0; i < docs.length; i++) {
            let pl = docs[i].data();
            let mediasFound = pl.list;
            for (let j = 0; j < mediasFound.length; j++) {
              if (mediasFound[j].media.id == this.model.id) {
                this.dialog.open(CommonDialog, {
                  width: "500px",
                  data: { pregunta: "Antes de borrar este archivo, debes de borrar su referencia desde la playlist " + pl.nombre, error: true, link: t.docs[0].id, tipo: "playlists" }
                });
                return;
              }
            }
            this.borrar();
          }
        })

      }
    });
  }

  borrar() {
    this.busy = true;
    this.mediaService.borrarMedia(this.media, this.model).then(() => {
      this.mediaService.borrarArchivo(this.model.ref).subscribe(t => {
        this.mediaService.borrarArchivo("thumbnail_" + this.model.ref).subscribe(t => {
        }, err => {
          this.dialog.open(CommonDialog, {
            width: "500px",
            data: { pregunta: "Ocurrio un error eliminado el archivo, por favor contacte con los administradores con la siguiente referencia: multimedia/delete/" + this.model.ref, error: true }
          });
        });
      }, err => {
        this.dialog.open(CommonDialog, {
          width: "500px",
          data: { pregunta: "Ocurrio un error eliminado el archivo, por favor contacte con los administradores con la siguiente referencia: multimedia/delete/" + this.model.ref, error: true }
        });
      })
    }).catch(err => {
      console.log(err);
      this.conErrores();
    }).finally(() => { this.desocupado(); this.sinErrores(); this.cerrar(); });

  }

  muestraBorrar(): boolean {
    return this.media && this.media != "0";
  }

  cerrar() {
    this.router.navigate(['/multimedia']);
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.media = params.get("id");
      if (this.media == "0") {
        this.tituloMedia = "Crear nuevo archivo multimedia";
        this.createForm();
      } else {
        this.mediaService.media(this.media).then(res => {
          this.model = res.data();
          this.model.id = this.media;
          this.updateForm(this.model);
        }, err => console.log("Error, la media no existe"))
      }
    });
  }
}
