import * as firebase from 'firebase';
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { BillingModel } from '../../modelos/billing.model';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class BillingService {
    public stripe: any;
    constructor(
        public db: AngularFirestore,
        public auth: AuthService,
        public dbRt: AngularFireDatabase,
    ) {
        this.stripe = (<any>window).Stripe('pk_test_oyjJjuwzVE85Z8cWsZVhevgz00rS60G19W');
    }

    billing(): Promise<any> {
        var billing = this.db.collection("stripe_customers").doc(this.auth.userId).ref.get();
        return billing;
    }

    activeSuscription(): Promise<any> {

        return new Promise<any>((res) => {
            var billing = this.dbRt.database.ref("billing/" + this.auth.company).once("value").then(t => { res(t) });
        });
    }

    create(m: BillingModel) {
        return firebase.functions().httpsCallable("createStripeUser")({ user: m }).then((response: any) => {

        });
    }

    subscribe(plan: {}) {
        return firebase.functions().httpsCallable("subscribe")({ plan: plan });
    }

    unsubscribe(subscription: string) {
        return firebase.functions().httpsCallable("unsubscribe")({ subscription: subscription });
    }

    getDetails() {
        return firebase.functions().httpsCallable("getStripeDetails")();
    }

    getNextRenewals(): Promise<any> {
        return firebase.functions().httpsCallable("infoSubscription")();
    }
}
