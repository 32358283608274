import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators, MinLengthValidator } from '@angular/forms';
import { ValueModel } from 'src/app/core/modelos/value.model';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';
import { AuthService } from 'src/app/core/auth.service';
import * as firebase from 'firebase';
import { MatDialog, MatSnackBar } from '@angular/material';
import { CommonDialog } from 'src/app/core/commondialog/common.dialog.component';
import { MediaService } from 'src/app/core/app/servicios/media.service';
import { MediaModel } from 'src/app/core/modelos/media.model';
import { PlaylistType } from 'src/app/core/enum/playlist-type';
import { MediaType } from 'src/app/core/enum/media-type';
import { DomSanitizer } from '@angular/platform-browser';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { MediaWrapperModel } from 'src/app/core/modelos/media-wrapper.model';

@Component({
  selector: 'scheduled-playlist',
  templateUrl: 'scheduled-playlist.component.html',
  styleUrls: ['scheduled-playlist.scss']
})
export class ScheduledPlaylistComponent implements OnInit {
  medias: MediaModel[];  
  playlist: string;
  busy: boolean = false;
  listForm: FormGroup;
  tituloPlaylist: string;
  ready: boolean = false;
  model: PlaylistModel = {nombre: "", descripcion: "Playlist " + (new Date()).toLocaleDateString('en-GB'), tipo: PlaylistType.Orgánica, company: this.authService.company, geopos: null, id: null, repetir: 1, prioridad: 1, list: []};
  tiposPlaylist: ValueModel[] = [{ valor: 0, nombre: "Orgánica" }, { valor: 1, nombre: "Pago"}];

  constructor(
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    public playlistService: PlaylistService,
    public authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private mediaService: MediaService,
    public sanitizer: DomSanitizer
  ) {

  }
  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cerrar", { politeness: "assertive", horizontalPosition: "center", verticalPosition: "top", duration: 3000 })
  }

  desocupado() {
    this.busy = false;
  }

  conErrores() {
    this.openSnackBar("Se produjo un error al intentar realizar la operación")
  }

  sinErrores() {
    this.openSnackBar("Operación completada")
  }

  

  cerrar() {
  
  }

  ngOnInit(): void {
  }
}
