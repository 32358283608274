import { Injectable } from "@angular/core";
import { AngularFirestore, DocumentSnapshot } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { CustomModel } from '../../modelos/custom.model';

@Injectable()
export class CustomService {
    constructor(
        public db: AngularFirestore,
        public auth: AuthService
    ) {

    }

    getCustom() : Promise<any> {        
        return this.db.collection("custom").doc(this.auth.company).ref.get();       
    }

    setCustom(custom: CustomModel): Promise<any> {
        return this.db.collection("custom").doc(this.auth.company).set(custom, {merge: true});
      }
}
