import { Component, ViewEncapsulation, ViewChild } from '@angular/core';
import { AuthService } from './core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSidenav, MatDialog } from '@angular/material';
import { CommonDialog } from './core/commondialog/common.dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  @ViewChild('snav', { static: false }) snav: MatSidenav;

  constructor(public authService: AuthService, private route: ActivatedRoute, private location: Location, public router: Router, public dialog: MatDialog) {
    //Si es una pantalla y se desconecta, volver a reconectar cuando vuelva a tener internet

    // window.addEventListener('online', (e) => {
    //   if (!authService.admin) {
    //     this.router.navigate(['/simulacion']);
    //   }
    // })

  }

  isTest(): boolean {
    return !environment.production;
  }

  seleccionado(name): string {
    if(name == this.router.url){
      return '#3f51b5';
    }
    return this.router.url.includes(name) ? (name != '/' ? '#3f51b5': 'white') : 'white'
  } n

  seleccionadoTexto(name): string {
    if(name == this.router.url){
      return 'white';
    }
    return this.router.url.includes(name) ? (name != '/' ? 'white': 'black') : 'black'
  }

  logout(nav: any) {
    this.authService.doLogout()
      .then((res) => {
        nav.close();
        this.location.back();
      }, (error) => {
        console.log("Logout error", error);
      });
  }

  profile() {
    this.router.navigate(['/user']);
  }

  personalizar() {
    this.router.navigate(['/personalizar']);
  }

  invitacion(){
    const dialogRef = this.dialog.open(CommonDialog, {
      width: '500px',
      data: { promptEmail: true }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.result){
        this.authService.invitacion(result.link);
      }
    });
  }

  workingMode(): boolean {
    return this.router.url != '/login' && this.router.url != '/register';
  }

  close(event: Event) {
    if ((<any>event.target).innerText == "menu") {
      return;
    }
    this.snav.close();
  }
}
