import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { PantallaService } from '../core/app/servicios/pantalla.service';
import { PantallaModel } from '../core/modelos/pantalla.model';
import { DomSanitizer } from '@angular/platform-browser';
import { MediaService } from '../core/app/servicios/media.service';
import { PlaylistService } from '../core/app/servicios/playlist.service';
import { interval, Observable, Subscription } from 'rxjs';
import { repeat, take, exhaustMap, max } from 'rxjs/operators';
import { MediaWrapperModel } from '../core/modelos/media-wrapper.model';
import { DatePipe } from '@angular/common';
import { OrdenesService } from '../core/app/servicios/ordenes.service';
import { CommandModel } from '../core/modelos/command.model';

@Component({
  selector: 'simulacion',
  templateUrl: 'simulacion.component.html',
  styleUrls: ['simulacion.scss']
})
export class SimulacionComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    this.toUnsuscribe.unsubscribe();
    this.toUnsuscribeStatus.unsubscribe();
    this.timer.unsubscribe();
  }
  example: Observable<number>;
  source: Observable<number>;

  liveness: Observable<number>;
  livenesSource: Observable<number>;
  livenessTimer: Subscription;

  pantalla: PantallaModel;
  suscripcion: Observable<any>;
  suscripcionStatus: Observable<any>;
  toUnsuscribe: any;
  toUnsuscribeStatus: any;
  timer: Subscription;
  currentTime: number = 0;
  maxDuracion: number = 0;
  arrayMedia: any[] = [];
  mediaWrapper: MediaWrapperModel[] = [];

  constructor(
    public authService: AuthService,
    private pantallaService: PantallaService,
    public sanitize: DomSanitizer,
    private mediaService: MediaService,
    private playlistService: PlaylistService,
    private ordenesService: OrdenesService
  ) {
    this.livenesSource = interval(300000);
    this.liveness = this.livenesSource.pipe(repeat());
    this.livenessTimer = this.liveness.subscribe(x => {
      this.processLiveness();
    });
  }

  menor(): string {
    let t = 0;
    for (let i = 0; i < this.mediaWrapper.length; i++) {
      t += this.mediaWrapper[i].duracion;
      if (this.currentTime < t) {
        return this.mediaWrapper[i].publicUrl;
      }
    }
    return this.mediaWrapper[0] ? this.mediaWrapper[0].publicUrl : null;
  }

  menorRaw(): string {
    let t = 0;
    for (let i = 0; i < this.mediaWrapper.length; i++) {
      t += this.mediaWrapper[i].duracion;
      if (this.currentTime < t) {
        return this.mediaWrapper[i].media.ref;
      }
    }
    return this.mediaWrapper[0] ? this.mediaWrapper[0].media.ref : null;
  }

  processLiveness() {
    this.ordenesService.livenessPantalla(this.id);
  }

  processTime() {
    if (this.maxDuracion < this.currentTime) {
      this.currentTime = 0;
    }

    let imagen = this.menor();

    this.thumb = imagen;

    this.currentTime++;
  }

  thumb: any;
  id: string;

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(t => {
      this.startSimulation();
      this.source = interval(1000);
      this.example = this.source.pipe(repeat());
      this.timer = this.example.subscribe(x => {
        this.processTime();
      });

      this.suscripcionStatus = this.ordenesService.tiempoReal();
      this.toUnsuscribeStatus = this.suscripcionStatus.subscribe(t => {
        if (t.type == "modified" || t.type == "added" || t.type == "value") {
          let orden: CommandModel = t.payload.data();
          if (orden.sender == "pantalla") {
            return;
          }
          if (orden.command == "status") {
            let img = this.menorRaw();
            this.reportStatus(img);
          } else if (orden.command == "update" || t.type == "added") {
            this.startSimulation();
          }
        }
      });
    })

  }
  private startSimulation() {
    this.pantallaService.miPantalla().subscribe((t) => {
      let data = t.data();
      if (!this.pantalla) {
        this.id = data.uid;
        this.processLiveness();
      }
      this.pantalla = data;
      this.mediaWrapper = [];
      this.arrayMedia = [];
      this.maxDuracion = 0;
      this.currentTime = 0;
      for (let i = 0; i < this.pantalla.playlist.length; i++) {
        let playListRef: string = this.pantalla.playlist[i];
        this.playlistService.playlist(playListRef).then(t => {
          let playlist = t.data();
          this.extractMedia(playlist);
        });
      }
    });
  }

  reportStatus(thumb: string) {
    this.ordenesService.reportStatus(thumb, this.id);
  }

  private extractMedia(playlist: any) {
    for (let i = 0; i < playlist.list.length; i++) {
      let m = playlist.list[i];
      this.mediaWrapper.push(m);
      this.maxDuracion += m.duracion;
      this.mediaService.referenciaCloudStorageRef(m.media.ref).getDownloadURL().subscribe(t => {
        this.arrayMedia.push(t);
        m.publicUrl = t;
      });
    }
  }
}
