import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { rootRouterConfig } from './app.routes';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user.component';
import { RegisterComponent } from './register/register.component';
import { UserResolver } from './user/user.resolver';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule, MatSnackBarModule, MatToolbarModule, MatIconModule, MatSelectModule, MatPaginatorModule, MatDialogModule, MatProgressBarModule, MatChipsModule, MatAutocompleteModule, MatSlideToggleModule } from '@angular/material';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HAMMER_LOADER } from '@angular/platform-browser';
import { MatDividerModule } from '@angular/material/divider';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);

import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { DashboardResolver } from './dashboard/dashboard.resolver';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PantallasComponent } from './pantallas/pantallas.component';
import { PantallaControlComponent } from './pantallas/control/pantalla-control.component';
import { EditorPantallaComponent } from './pantallas/pantalla/editor-pantalla.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PantallaService } from './core/app/servicios/pantalla.service';
import { CommonDialog } from './core/commondialog/common.dialog.component';
import { MediaService } from './core/app/servicios/media.service';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { EditorMediaComponent } from './media/control/editor-media.component';
import { MediasComponent } from './media/medias.component';
import { MatFileUploadModule } from 'mat-file-upload'
import { SingleMediaComponent } from './media/control/single-media/single-media.component';
import { PlaylistControlComponent } from './playlists/control/playlist-control.component';
import { PlaylistService } from './core/app/servicios/playlist.service';
import { PlaylistsComponent } from './playlists/playlists.component';
import { EditorPlaylistComponent } from './playlists/playlist/editor-playlist.component';
import { SimulacionComponent } from './simulacion/simulacion.component';
import { GruposService } from './core/app/servicios/grupos-service';
import { OrdenesService } from './core/app/servicios/ordenes.service';
import { ChartsModule } from 'ng2-charts';
import { HttpClientModule } from '@angular/common/http';
import { StatsService } from './core/app/servicios/stats-service';
import { BillingService } from './core/app/servicios/billing-service';
import { GruposComponent } from './grupos/grupos.component';
import { CustomService } from './core/app/servicios/custom-service';
import { CustomizeComponent } from './customize/customize.component';
import { PlaylistCreatorComponent } from './playlists/creator/playlist-creator.component';
import { ScheduledDialog } from './playlists/scheduled/dialog/scheduled.dialog.component';
import { ScheduledPlaylistComponent } from './playlists/scheduled/scheduled-playlist.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserComponent,
    PantallasComponent,
    RegisterComponent,
    GruposComponent,
    DashboardComponent,
    PantallaControlComponent,
    EditorPantallaComponent,
    EditorMediaComponent,
    MediasComponent,
    SingleMediaComponent,
    PlaylistControlComponent,
    PlaylistsComponent,
    EditorPlaylistComponent,
    SimulacionComponent,
    CommonDialog,
    ScheduledDialog,
    CustomizeComponent,
    PlaylistCreatorComponent,
    ScheduledPlaylistComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule, 
    ReactiveFormsModule,
    FormsModule,    
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    AngularFireStorageModule,
    BrowserAnimationsModule,
    MatFileUploadModule,
    AngularFireDatabaseModule,
    MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatToolbarModule, MatChipsModule, ChartsModule,
    MatSnackBarModule, MatIconModule, MatSidenavModule, MatListModule, MatCardModule, MatTooltipModule, MatAutocompleteModule,
    MatSelectModule, MatPaginatorModule, MatDialogModule, MatProgressBarModule, DragDropModule, FormsModule, MatProgressSpinnerModule,
    MatMenuModule, MatDividerModule, MatSlideToggleModule, CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    })
  ],
  entryComponents: [CommonDialog, ScheduledDialog],
  exports: [
    MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatSnackBarModule, MatChipsModule, ChartsModule,
    MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatCardModule, MatTooltipModule, MatAutocompleteModule,
    MatSelectModule, MatPaginatorModule, MatDialogModule, MatProgressBarModule, DragDropModule, FormsModule, MatProgressSpinnerModule,
    MatMenuModule, MatDividerModule, MatSlideToggleModule,
    MatFileUploadModule],

  providers: [AuthService, UserService, UserResolver, AuthGuard, DashboardResolver, PantallaService, MediaService, PlaylistService, GruposService, OrdenesService, StatsService, BillingService,
    {
      provide: HAMMER_LOADER,
      useValue: () => new Promise(() => {})
    }, CustomService],
  bootstrap: [AppComponent]
})
export class AppModule { }
