import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../core/auth.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { GruposService } from '../core/app/servicios/grupos-service';
import { PlaylistService } from '../core/app/servicios/playlist.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CustomModel } from '../core/modelos/custom.model';
import { CustomService } from '../core/app/servicios/custom-service';
import { MediaService } from '../core/app/servicios/media.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'customize',
  templateUrl: 'customize.component.html',
  styleUrls: ['customize.scss']
})
export class CustomizeComponent implements OnInit {

  info: MatSnackBarConfig = { horizontalPosition: "center", verticalPosition: "top", duration: 3000 };
  loginForm: FormGroup;
  errorMessage: string = '';
  allGroups: string[] = [];
  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private customService: CustomService,
    private mediaService: MediaService) {
    this.ready = false;
  }

  model: CustomModel;
  ngOnInit(): void {
    this.customService.getCustom().then(t => {
      this.model = t.data();
      if (!this.model) {
        this.model = { mail: null, name: null, background: '#000000', logo: null };
      }else{
        this.mediaService.referenciaCloudStorageRef(this.model.logo).getDownloadURL().subscribe(t => {
          this.thumb = t;
        });
      }
      this.ready = true;
    });
  }

  isImage(str): boolean {
    return str.toLowerCase().endsWith("jpg") || str.toLowerCase().endsWith("jpeg") || str.toLowerCase().endsWith("gif") || str.toLowerCase().endsWith("bmp") || str.toLowerCase().endsWith("png");
  }

  structFile: any;
  selectedFile: string;
  thumb: string;
  event: any;
  onSelectedFilesChanged(event: any) {
    if (event) {
      this.event = event[0];
      this.selectedFile = event[0].name;
      let parts = this.selectedFile.split(".");
      this.structFile = event[0];
      if (this.isImage(this.selectedFile)) {
        let name = this.mediaService.newGuid() + '.' + parts[parts.length - 1];
        this.thumb = URL.createObjectURL(event[0]);
        let ref = this.mediaService.referenciaCloudStorage("media/" + this.authService.company + "/" + name);
        let task = this.mediaService.reauthenticateWithTokenFor("media/" + this.authService.company + "/" + name);
        task.then(t => {
          let uploading = this.mediaService.tareaCloudStorage("media/" + this.authService.company + "/" + name, this.structFile);
            uploading.then(() => { 
              this.sinErrores();
              this.model.logo = name;
            }).catch(err => {
              ref.delete();
            });
        }, error => {
          this.openSnackBar("Se produjo un error al intentar realizar la operación");
          console.log(error);
        }).finally(() => {
          this.desocupado();
        });
      }
    }
  }

  openSnackBar(msg: string) {
    this._snackBar.open(msg, "Cerrar", { politeness: "assertive", horizontalPosition: "center", verticalPosition: "top", duration: 3000 })
  }

  public busy = false;
  desocupado() {
    this.busy = false;
  }

  conErrores() {
    this.openSnackBar("Se produjo un error al intentar realizar la operación")
  }

  sinErrores() {
    this.openSnackBar("Operación completada")
  }


  ready: boolean = false;
  salvar() {
    this.customService.setCustom(this.model).then(t => {
      this.openSnackBar("Operación completada");
    });
  }

  cerrar() {
    this.router.navigate(['/']);
  }

  public navigate(to: string): void {
    this.router.navigate(['/pantallas/' + to]);
  }
}