// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDFMc-Pvh-cEMPozxQPtaOmMuUFSh_-6JM",
    authDomain: "screenads-16b77.firebaseapp.com",
    databaseURL: "https://screenads-16b77.firebaseio.com",
    projectId: "screenads-16b77",
    storageBucket: "screenads-16b77.appspot.com",
    messagingSenderId: "253409971040",
    appId: "1:253409971040:web:877854b0c21dc18e69ec05"
  }
};