import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../core/user.service';
import { AuthService } from './auth.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(t): Promise<boolean>{
    if(t.routeConfig.path == "register"){
      return new Promise((resolve, reject) => {
        return resolve(true);
      })
    }
    return new Promise((resolve, reject) => {
      this.userService.getCurrentUser()
      .then(user => {
        if (this.afAuth.auth.currentUser != null) {
          this.afAuth.auth.currentUser.getIdTokenResult()
            .then((idTokenResult) => {
              // Confirm the user is an Admin.
              this.authService.company = idTokenResult.claims.company;
              resolve(idTokenResult.claims.admin);
            }, err => console.log(err));
        }
        return resolve(true);
      }, err => {
        this.router.navigate(['/login']);
        return resolve(false);
      })
    })
  }
}
