import * as firebase from 'firebase';
import { Injectable } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { debug } from 'util';

@Injectable()
export class StatsService {

    constructor(
        public db: AngularFirestore,
        public dbRt: AngularFireDatabase,
        public auth: AuthService
    ) {
    }

    daysIntoYear() {
        let date = new Date()
        return (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) - Date.UTC(date.getFullYear(), 0, 0)) / 24 / 60 / 60 / 1000;
    }

    weekIntoYear() {
        return Math.floor(this.daysIntoYear() / 7);
    }

    year(): number {
        let date = new Date();
        return date.getFullYear();
    }

    month(): number {
        let date = new Date();
        return date.getMonth() + 1;
    }

    buildTenWeeksArrayFromNow(): number[] {
        let data = [];
        let week = this.daysIntoYear();
        for (let i = week; i > week + 10; i--) {
            data.push(i);
        }
        data = data.reverse();
        return data;
    }

    private buildKeyCompanyWeek(key: string, UID: string, weekOffset: number): string {
        return (this.auth.company + "_" + UID + "_Y" + (this.weekIntoYear() + weekOffset) + "_" + this.year() + "Y_" + key).toUpperCase();
    }

    private buildKeyCompanyMonth(key: string, UID: string, monthOffset: number): string {
        return (this.auth.company + "_" + UID + "_M" + (this.month() + monthOffset) + "_" + this.year() + "Y_" + key).toUpperCase();
    }

    //cache things
    public hoursPerWeek: any = {};
    public getHoursPerWeek(UID: string): Promise<number[]> {
        return new Promise(resolve => {
            if (this.hoursPerWeek.UID != null) {
                return resolve(this.hoursPerWeek.UID.reverse());
            } else {
                let endCondition = 0;
                this.hoursPerWeek.UID = [];
                for (let i = 0; i < 10; i++) {
                    let key = this.buildKeyCompanyWeek("USAGEHOURS", UID, -i);
                    console.log(key);
                    this.dbRt.database.ref('/mediatek/stats/' + key).once("value").then(t => {
                        endCondition++;
                        let value = t.val();
                        if (!value) {
                            value = 0;
                        }
                        this.hoursPerWeek.UID.push(value);
                        if (endCondition == 10) {

                            return resolve(this.hoursPerWeek.UID.reverse());
                        }
                    });
                }
            }
        });
    }

    //cache things
    public peoplePerHour: any = {};
    public getPeoplePerHourPerWeek(UID: string): Promise<number[]> {
        return new Promise(resolve => {
            if (this.peoplePerHour.UID != null) {
                return resolve(this.peoplePerHour.UID.reverse());
            } else {
                let endCondition = 0;
                this.peoplePerHour.UID = [];
                for (let i = 0; i < 10; i++) {
                    let key = this.buildKeyCompanyWeek("PEOPLEHOURSWEEK", UID, -i);
                    console.log(key);
                    this.dbRt.database.ref('/mediatek/stats/' + key).once("value").then(t => {
                        endCondition++;
                        let value = t.val();
                        if (!value) {
                            value = 0;
                        }
                        this.peoplePerHour.UID.push(value);
                        if (endCondition == 10) {
                            return resolve(this.peoplePerHour.UID.reverse());
                        }
                    });
                }
            }
        });
    }

    public freshness: Date = null;
    private now(): number {
        let d = new Date();
        return d.getTime();
    }

    private elapsed(): number {
        if (this.freshness) {
            return this.now() - this.freshness.getTime()
        } else {
            this.freshness = new Date();
            return Infinity;
        }
    }

    public totalStorage: any = null;
    public getTotalStorage(): Promise<number> {
        if (this.totalStorage) {
            return new Promise((resolve) => {
                resolve(this.storageLeft);
            })
        }
        return this.dbRt.database.ref(this.auth.company + "/membership").once('value').then(t => {
            let user = t.val();
            if (user == 'free') {
                this.totalStorage = 25;
                return new Promise(resolve => resolve(25));;
            } else if (user == 'premium') {
                this.totalStorage = 500;
                return new Promise(resolve => resolve(500));;
            } else if (user == 'ulta') {
                this.totalStorage = 1000;
                return new Promise(resolve => resolve(1000));;
            }
        });

    }

    public storageLeft: any = null;
    public printStorageLeft(): Promise<any> {
        return new Promise(resolve => {
            if (this.storageLeft) {
                resolve(this.storageLeft);
            } else {
                return this
                    .storageLeftInBytes()
                    .then(storageLeftInBytes => {
                        this.storageLeft = parseFloat(storageLeftInBytes);
                        return resolve(storageLeftInBytes);
                    });
            }
        })
    }

    public storageLeftInBytes(): Promise<any> {
        if (this.elapsed() < 600000) {
            return new Promise((resolve) => {
                resolve((Math.round(this.storageLeft / 1000000 * 100) / 100).toFixed(2))
            })
        } else {
            return this.auth.afAuth.auth
                .currentUser
                .getIdTokenResult()
                .then((idToken) => {
                    return firebase.functions().httpsCallable("storageLeftInBytes")({ token: idToken.token }).then((response: any) => {    
                        this.storageLeft = parseFloat((Math.round(response.data.storageLeftInBytes / 1000000 * 100) / 100).toFixed(2));                    
                        return (Math.round(response.data.storageLeftInBytes / 1000000 * 100) / 100).toFixed(2);
                    });
                });
        }
    }


}
