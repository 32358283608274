import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../core/user.service';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../core/user.model';
import { PlaylistService } from '../core/app/servicios/playlist.service';
import { PlaylistModel } from '../core/modelos/playlist.model';
import { interval, Observable } from 'rxjs';
import { repeat, take, exhaustMap } from 'rxjs/operators';
import { MediasComponent } from '../media/medias.component';
import { MediaService } from '../core/app/servicios/media.service';

@Component({
  selector: 'page-playlists',
  templateUrl: 'playlists.component.html',
  styleUrls: ['playlists.scss']
})
export class PlaylistsComponent implements OnInit, OnDestroy {
  ngOnDestroy(): void {
    
  }
  playlists: PlaylistModel[];
  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  actualThumb: string;
  thumbDictionary: any = [];
  iteracion: number = -1;
  example: Observable<number>;
  source: Observable<number>;

  constructor(
    public userService: UserService,
    public authService: AuthService,
    public route: ActivatedRoute,
    public location: Location,
    public fb: FormBuilder,
    private router: Router,
    public playlistService: PlaylistService,
    private mediaService: MediaService
  ) {
    
  }

  ngOnInit(): void {
    this.playlistService.playlists().then(res => {
      this.playlists = res.docs.map(t => {
        let model = t.data();
        model.id = t.id;
        this.iteracion = -model.list.length;        
        return model;
      });
    }, err => console.log(err))
  }



  nuevaPlaylist() {
    this.router.navigate(['/playlists/0']);
  }

  irPlaylist(x) {
    this.router.navigate(['/playlists/' + x.id]);
  }
}
