import { Inject, Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { PlaylistService } from 'src/app/core/app/servicios/playlist.service';
import { PlaylistModel } from 'src/app/core/modelos/playlist.model';

export interface DialogResult {
    promptEmail: boolean;
    pregunta: string;
    result: any;
    error: boolean;
    tipo: string;
    link: string;
    event: any;
    selected: any;
    borrar: boolean;
}

@Component({
    selector: 'scheduled-dialog',
    templateUrl: 'scheduled.dialog.component.html',
    styleUrls: ['scheduled.scss']
})
export class ScheduledDialog implements OnInit{
    selected: any;
    niceColorsSecondary = ['#e6194B', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#42d4f4', '#f032e6', '#bfef45', '#fabebe', '#469990', '#e6beff', '#9A6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#a9a9a9', '#ffffff', '#000000']
    playlists: PlaylistModel[];
    iteracion: number = -1;
    
    ngOnInit(): void {
        this.playlistService.playlists().then(res => {
          this.playlists = res.docs.map(t => {
            let model = t.data();
            model.id = t.id;
            this.iteracion = -model.list.length;        
            return model;
          });
        }, err => console.log(err))
      }

    event: any;
    constructor(
        public dialogRef: MatDialogRef<ScheduledDialog>,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public data: DialogResult,
        private playlistService: PlaylistService) { 
            this.selected = data.selected;
            this.event = data.event;
        }

    borrar(){
        this.data.result = {};
        this.data.result.borrar = true;
        this.data.result.event = this.event;
        this.dialogRef.close(this.data.result);
    }

    cerrar(){        
        this.dialogRef.close(null);
    }

    save(playlist: PlaylistModel, color: string): void {
        this.data.result = {};
        this.data.result.playlist = playlist;
        this.data.result.selected = color;
        this.data.result.event = this.event;
        this.dialogRef.close(this.data.result);
    }
}